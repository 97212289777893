export enum InfoBannerLevel {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
  Danger = 'danger',
  Trust = 'trust',
}

export enum InfoBannerScreen {
  Reviews = 'reviews',
  VASCheckout = 'vas_checkout',
  ItemViewOfflineAuthenticity = 'item_view_offline_auth_modal',
  TermsAndConditions = 'terms_and_conditions',
}
