'use client'

import { X24 } from '@vinted/monochrome-icons'
import { Button, Cell, Image, Text, Spacer, Modal, Navigation } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'

type Props = {
  show: boolean
  messageType: 'item' | 'extra_service'
  onClose: () => void
}

const SalesTaxModal = ({ show, messageType, onClose }: Props) => {
  const translate = useTranslate('sales_tax_modal')
  const asset = useAsset('/assets/sales-tax')

  return (
    <Modal show={show}>
      <Navigation
        right={
          <Button
            inline
            iconName={X24}
            styling={Button.Styling.Flat}
            testId="sales-tax-modal--navigation-close"
            onClick={onClose}
          />
        }
      />
      <div className="u-text-center">
        <Image
          src={asset('/sales-tax.svg')}
          size={Image.Size.XLarge}
          alt=""
          testId="sales-tax-modal--icon"
        />
        <Spacer size={Spacer.Size.XLarge} />
        <Text text={translate('title')} type={Text.Type.Heading} testId="sales-tax-modal--title" />
      </div>
      <Cell>
        <Spacer size={Spacer.Size.XLarge} />
        <Text text={translate(`message.${messageType}`)} testId="sales-tax-modal--description" />
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          text={translate('actions.close')}
          styling={Button.Styling.Filled}
          onClick={onClose}
          testId="sales-tax-modal--close"
        />
      </Cell>
    </Modal>
  )
}

export default SalesTaxModal
