'use client'

import { useSelector } from 'react-redux'
import { Icon, Spacer, Text } from '@vinted/web-ui'
import { Discover24, Mastercard24, Visa24 } from '@vinted/payment-icons'

import useTranslate from 'hooks/useTranslate'
import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'

import SeparatedList from 'components/SeparatedList'
import { PayInMethodIncentive } from 'components/PayInMethodSelection/types'

type Props = {
  note?: string | null
  incentive?: PayInMethodIncentive | null
}

const CreditCardNote = ({ note, incentive }: Props) => {
  const locale = useSelector(getLocale)
  const translate = useTranslate('payment_method_selection.notes')

  function renderIcon(icon: ComponentProps<typeof Icon>['name']) {
    return <Icon name={icon} />
  }

  function getTitle() {
    if (incentive) {
      const amount = formatCurrencyAmount(incentive.amount, locale, {
        keepFractionDigits: false,
      })
      const incentiveNoteKey = incentive.isDelayed
        ? 'credit_card_delayed_incentive'
        : 'credit_card_instant_incentive'

      return translate(incentiveNoteKey, { amount })
    }

    return note || translate('credit_card')
  }

  return (
    <>
      <Text text={getTitle()} />
      <Spacer size={Spacer.Size.XSmall} />
      <SeparatedList
        separator={<Spacer size={Spacer.Size.XLarge} orientation={Spacer.Orientation.Vertical} />}
      >
        {renderIcon(Mastercard24)}
        {renderIcon(Visa24)}
        {renderIcon(Discover24)}
      </SeparatedList>
    </>
  )
}

export default CreditCardNote
