'use client'

import { ReactElement } from 'react'

import List from 'components/List'
import { PayInMethodModel } from 'types/models'

import PayInMethod from './PayInMethod'

type PayInMethodAvailability = {
  isDisabled: boolean
  payInMethod: PayInMethodModel
}

type Props = {
  payInMethods: Array<PayInMethodAvailability>
  renderSuffix: (payInMethod: PayInMethodModel) => ReactElement
  renderBody: (payInMethod: PayInMethodModel) => ReactElement | null
  renderBadge: (payInMethod: PayInMethodModel) => ReactElement | null
  onItemClick: (payInMethod: PayInMethodModel) => void
}

const PayInMethodList = ({
  payInMethods,
  renderSuffix,
  renderBody,
  renderBadge,
  onItemClick,
}: Props) => {
  const handleItemClick = (payInMethod: PayInMethodModel) => onItemClick(payInMethod)

  return (
    <List>
      {payInMethods.map(({ isDisabled, payInMethod }) => (
        <PayInMethod
          key={payInMethod.id}
          isDisabled={isDisabled}
          payInMethod={payInMethod}
          body={renderBody(payInMethod)}
          badge={renderBadge(payInMethod)}
          suffix={renderSuffix(payInMethod)}
          onClick={handleItemClick}
        />
      ))}
    </List>
  )
}

export default PayInMethodList
