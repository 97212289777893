'use client'

import { ReactElement, ReactNode } from 'react'
import { Cell, Spacer, Text } from '@vinted/web-ui'

import PayInMethodIcon from 'components/PayInMethodIcon'

import { PayInMethodModel } from 'types/models'

type Props = {
  isDisabled?: boolean
  suffix?: ReactElement
  body?: ReactNode
  badge?: ReactElement | null
  payInMethod: PayInMethodModel
  onClick: (payInMethod: PayInMethodModel) => void
}

const PayInMethod = ({ isDisabled, suffix, body, badge, payInMethod, onClick }: Props) => {
  const handleClick = () => () => onClick(payInMethod)

  const renderTitle = () => {
    const { translatedName } = payInMethod

    return (
      <>
        <Text text={translatedName} bold theme="amplified" />
        {badge && (
          <>
            <Spacer orientation={Spacer.Orientation.Vertical} />
            {badge}
            {body && <Spacer orientation={Spacer.Orientation.Horizontal} />}
          </>
        )}
      </>
    )
  }

  const { icon, code } = payInMethod

  return (
    <Cell
      disabled={isDisabled}
      type={Cell.Type.Navigating}
      onClick={handleClick()}
      title={renderTitle()}
      prefix={<PayInMethodIcon payInMethodBrand={icon} />}
      suffix={suffix}
      body={body}
      testId={`pay-in-method-${code}`}
    />
  )
}

export default PayInMethod
