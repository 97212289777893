'use client'

import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import { Text, Cell, Image, Spacer } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useIsDarkMode from 'hooks/useIsDarkMode'

import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { CheckoutPayInMethodPromotionModel } from 'types/models'

type Props = {
  payInMethodPromotion: CheckoutPayInMethodPromotionModel
}

const PayInMethodSelectionBanner = ({
  payInMethodPromotion: { type, amount, expiryDate },
}: Props) => {
  const intl = useIntl()
  const isDarkMode = useIsDarkMode()
  const locale = useSelector(getLocale)
  const translate = useTranslate(`payment_method_selection.banner.${type}`)
  const asset = useAsset()

  const getIconSrc = () => {
    const iconName = type === 'recommendation' ? 'hand-sparkle' : 'gift-truck'

    return asset(`${iconName}.svg`, { theme: { dark: `${iconName}-dark.svg` } })
  }

  const getFormattedExpiryDate = (date: string) => {
    return intl.formatDate(new Date(date), {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  const className = classNames('pay-in-method-selection-banner', {
    'pay-in-method-selection-banner--dark': isDarkMode,
  })

  const formattedAmount =
    amount &&
    formatCurrencyAmount(amount, locale, {
      keepFractionDigits: false,
    })

  return (
    <div data-testid="pay-in-method-selection-banner" className={className}>
      <Cell
        title={translate('title', { amount: formattedAmount })}
        body={
          <>
            <Text text={translate('body')} type={Text.Type.Body} />
            {expiryDate && (
              <>
                <Spacer />
                <Text
                  text={translate('expiry_date', {
                    expiryDate: getFormattedExpiryDate(expiryDate),
                  })}
                  type={Text.Type.Subtitle}
                />
              </>
            )}
          </>
        }
        suffix={<Image src={getIconSrc()} size={Image.Size.Large} alt="" />}
        theme="transparent"
      />
    </div>
  )
}

export default PayInMethodSelectionBanner
