'use client'

import { useEffect, ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { Blik48 } from '@vinted/payment-icons'
import { Text, Cell, Modal, Button, Spacer, InputText, Icon } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { buyerViewCheckoutEvent, clickEvent } from 'libs/common/event-tracker/events'

import { InputMode } from 'types/components'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  show: boolean
  trackingDetails?: {
    transactionId: number
  }
  onCancel: () => void
  onInputConfirmed: (blikCode: string) => void
}

enum FormField {
  BlikCode = 'blikCode',
}

type FormModel = {
  [FormField.BlikCode]: string
}

const BLIK_CODE_LENGTH = 6

const BlikCodeInputModal = ({ show, onCancel, trackingDetails, onInputConfirmed }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('blik_direct_payment_method.code_input_modal')
  const { reset, register, formState, getValues, setValue, handleSubmit } = useForm<FormModel>({
    mode: 'onChange',
    defaultValues: {
      [FormField.BlikCode]: '',
    },
  })

  const { isValid } = formState

  useEffect(() => {
    if (!show) return

    track(
      buyerViewCheckoutEvent({
        screen: Screen.BlikCodeRequest,
        transactionId: trackingDetails?.transactionId ?? null,
      }),
    )
  }, [show, track, trackingDetails])

  const trackPurchaseClick = () => {
    const targetDetails = trackingDetails?.transactionId
      ? JSON.stringify({
          transaction_id: trackingDetails.transactionId,
        })
      : null

    track(
      clickEvent({
        targetDetails,
        screen: Screen.BlikCodeRequest,
        target: ClickableElement.PayWithBlikCode,
      }),
    )
  }

  const handleOnPurchaseClick = () => {
    const values = getValues()
    const blikCode = values[FormField.BlikCode]

    reset()
    onInputConfirmed(blikCode)
    trackPurchaseClick()
  }

  const handleOnCancel = () => {
    reset()
    onCancel()
  }

  const applyBlikCodeLengthLimit = (event: ChangeEvent<HTMLInputElement>) => {
    const blikCode = event.target.value

    setValue(FormField.BlikCode, blikCode.substring(0, BLIK_CODE_LENGTH))
  }

  return (
    <Modal show={show}>
      <Cell>
        <div className="u-text-center">
          <Icon name={Blik48} />
          <Spacer size={Spacer.Size.XSmall} />
          <Text
            type={Text.Type.Heading}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
          <Spacer size={Spacer.Size.Large} />
          <Text html type={Text.Type.Body} text={translate('description')} />
        </div>
        <Spacer size={Spacer.Size.X2Large} />
        <InputText
          {...register(FormField.BlikCode, {
            required: true,
            minLength: BLIK_CODE_LENGTH,
            maxLength: BLIK_CODE_LENGTH,
            onChange: applyBlikCodeLengthLimit,
          })}
          type={InputText.Type.Number}
          inputMode={InputMode.Decimal}
          styling={InputText.Styling.Tight}
          placeholder={translate('placeholder')}
          uncontrolled
        />
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          disabled={!isValid}
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          text={translate('actions.complete_purchase')}
          onClick={handleSubmit(handleOnPurchaseClick)}
          testId="blik-code-input-modal-submit-button"
        />
        <Spacer />
        <Button
          styling={Button.Styling.Flat}
          text={translate('actions.cancel')}
          onClick={handleOnCancel}
        />
      </Cell>
    </Modal>
  )
}

export default BlikCodeInputModal
