'use client'

import { useState } from 'react'
import { X12 } from '@vinted/monochrome-icons'
import {
  BottomSheet,
  Button,
  Cell,
  Divider,
  Modal,
  Navigation,
  Spacer,
  Text,
  Icon,
} from '@vinted/web-ui'

import useBreakpoint from 'hooks/useBreakpoint'
import useIncrementalStorageCount from 'hooks/useIncrementalStorageCount'
import useTranslate from 'hooks/useTranslate'

import { InfoBannerModel } from 'types/models'

import { levelColorMap, levelIcon64Map } from './constants'

type Props = {
  screen: string
  banner: InfoBannerModel
}

const ExtraNotice = ({ screen, banner }: Props) => {
  const renderCount = useIncrementalStorageCount(`info_banner_extra_notice_${screen}`)
  const [isOpen, setIsOpen] = useState(renderCount === 1)
  const breakpoints = useBreakpoint()
  const translate = useTranslate()

  const title = banner.title || translate('info_banners.extra_notice.title')

  function handleClose() {
    setIsOpen(false)
  }

  function renderBody() {
    return (
      <>
        <div className="u-text-center">
          <Icon name={levelIcon64Map[banner.level]} color={levelColorMap[banner.level]} />
        </div>
        <Spacer size={Spacer.Size.Large} />
        <Text text={banner.body} html />
      </>
    )
  }

  if (breakpoints.phones) {
    return (
      <BottomSheet isVisible={isOpen} onClose={handleClose} title={title}>
        <Cell>{renderBody()}</Cell>
      </BottomSheet>
    )
  }

  return (
    <Modal show={isOpen} defaultCallback={handleClose} closeOnOverlay>
      <Navigation
        body={
          <Cell styling={Cell.Styling.Narrow}>
            <Text text={title} type={Text.Type.Title} />
          </Cell>
        }
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={handleClose}
            iconName={X12}
            theme="amplified"
            size={Button.Size.Small}
            inline
          />
        }
      />
      <Divider />
      <Cell styling={Cell.Styling.Wide} body={renderBody()} />
    </Modal>
  )
}

export default ExtraNotice
